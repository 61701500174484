/*
This File is autogenerated directly from Figma by ZOL Theme Generator.
Don't change it, your modifications may be deleted.
*/

:root {
  /* BREAKPOINTS */
  --mobile: 768px;
  --tablet: 1024px;
  --laptop: 1200px;
  --desktop: 1440px;

  /* COLORS */
  --beige: #f9f8f0;
  --black: #032230;
  --black-alpha-2: rgba(3, 34, 48, 0.2);
  --black-alpha-5: rgba(3, 34, 48, 0.5);
  --black-alpha-7: rgba(3, 34, 48, 0.7);
  --blue: #004666;
  --blue-100: #eceee9;
  --grey: #bfc2c4;
  --grey-alpha-1: rgba(191, 194, 196, 0.1);
  --red: #e64010;
  --red-100: #ffddd3;
  --white: #ffffff;
  --yellow-200: #faefc0;
  --yellow-500: #ffcc00;

  /* FONTS */
  --font-h1-desktop-default: 500 5rem/5.5rem var(--oswald-font);
  --font-h1-mobile-default: 500 3.5rem/4rem var(--oswald-font);
  --font-h2-desktop-default: 500 4rem/4.5rem var(--oswald-font);
  --font-h2-mobile-default: 500 2.5rem/3rem var(--oswald-font);
  --font-h3-desktop-default: 500 1.5rem/2rem var(--lato-font);
  --font-h3-mobile-default: 500 1.25rem/1.5rem var(--lato-font);
  --font-p1-desktop-default: 500 1.125rem/1.5rem var(--lato-font);
  --font-p1-mobile-default: 500 1rem/1.5rem var(--lato-font);
  --font-p2-desktop-default: 500 1rem/1.25rem var(--lato-font);
  --font-p2-mobile-default: 500 0.875rem/1.125rem var(--lato-font);
  --font-p3-desktop-default: 500 0.75rem/1rem var(--lato-font);
  --font-p3-mobile-default: 500 0.75rem/1rem var(--lato-font);

  /* SPACING */
  --spacing-12: 0.75rem;
  --spacing-64: 4rem;
  --spacing-80: 5rem;
  --spacing-16: 1rem;
  --spacing-8: 0.5rem;
  --spacing-160: 10rem;
  --spacing-40: 2.5rem;
  --spacing-24: 1.5rem;
  --spacing-4: 0.25rem;

  /* RADIUS */
  --radius-s: 2px;

  /* EFFECTS */
  --background-blur: null;

  /* GRID LAYOUT */
  --size-row: 131px;
  --grid-template-desktop: 
    [full-start] 1fr 
    [main-start] repeat(12,minmax(0,var(--size-row))) [main-end] 
    1fr [full-end];

  --grid-template-mobile: 
    [full-start] 1fr 
    [main-start] repeat(12,minmax(0,var(--size-row))) [main-end] 
    1fr [full-end];
}

/*
**** UTILS ****
*/

/* COLORS */
.background-color-beige {
  background-color: var(--beige);
}
.font-color-beige {
  color: var(--beige);
}
.border-color-beige {
  border-color: var(--beige);
}
.background-color-black {
  background-color: var(--black);
}
.font-color-black {
  color: var(--black);
}
.border-color-black {
  border-color: var(--black);
}
.background-color-blue {
  background-color: var(--blue);
}
.font-color-blue {
  color: var(--blue);
}
.border-color-blue {
  border-color: var(--blue);
}
.background-color-grey {
  background-color: var(--grey);
}
.font-color-grey {
  color: var(--grey);
}
.border-color-grey {
  border-color: var(--grey);
}
.background-color-red {
  background-color: var(--red);
}
.font-color-red {
  color: var(--red);
}
.border-color-red {
  border-color: var(--red);
}
.background-color-white {
  background-color: var(--white);
}
.font-color-white {
  color: var(--white);
}
.border-color-white {
  border-color: var(--white);
}
.background-color-yellow-200 {
  background-color: var(--yellow-200);
}
.font-color-yellow-200 {
  color: var(--yellow-200);
}
.border-color-yellow-200 {
  border-color: var(--yellow-200);
}
.background-color-yellow-500 {
  background-color: var(--yellow-500);
}
.font-color-yellow-500 {
  color: var(--yellow-500);
}
.border-color-yellow-500 {
  border-color: var(--yellow-500);
}

/* FONTS */
.h1-desktop-default {
  font: var(--font-h1-desktop-default);
  font-weight: 800;
}
.h2-desktop-default {
  font: var(--font-h2-desktop-default);
  font-weight: 800;
}
.h3-desktop-default {
  font: var(--font-h3-desktop-default);
}
.h3-desktop-bold {
  font: var(--font-h3-desktop-default);
  font-weight: 800;
}

.p1-desktop-default {
  font: var(--font-p1-desktop-default);
}
.p1-desktop-bold {
  font: var(--font-p1-desktop-default);
  font-weight: 800;
}
.p2-desktop-default {
  font: var(--font-p2-desktop-default);
}
.p2-desktop-bold {
  font: var(--font-p2-desktop-default);
  font-weight: 800;
}
.p3-desktop-default {
  font: var(--font-p3-desktop-default);
}
.p3-desktop-bold {
  font: var(--font-p3-desktop-default);
  font-weight: 800;
}

@media (max-width: 768px) {
  .h1-mobile-default {
    font: var(--font-h1-mobile-default);
    font-weight: 800;
  }
  .h2-mobile-default {
    font: var(--font-h2-mobile-default);
    font-weight: 800;
  }
  .h3-mobile-default {
    font: var(--font-h3-mobile-default);
  }
  .h3-mobile-bold {
    font: var(--font-h3-mobile-default);
    font-weight: 800;
  }
  .p1-mobile-default {
    font: var(--font-p1-mobile-default);
  }
  .p1-mobile-bold {
    font: var(--font-p1-mobile-default);
    font-weight: 800;
  }
  .p2-mobile-default {
    font: var(--font-p2-mobile-default);
  }
  .p2-mobile-bold {
    font: var(--font-p2-mobile-default);
    font-weight: 800;
  }
  .p3-mobile-default {
    font: var(--font-p3-mobile-default);
  }
  .p3-mobile-bold {
    font: var(--font-p3-mobile-default);
    font-weight: 800;
  }
}

/* SPACING */

/* GAP */
.gap-4 {
  gap: var(--spacing-4);
}

.gap-8{
  gap: var(--spacing-8);
}

.gap-16{
  gap: var(--spacing-16);
}

.gap-24{
  gap: var(--spacing-24);
}

.gap-40{
  gap: var(--spacing-40);
}

.gap-64{
  gap: var(--spacing-64);
}

.gap-80{
  gap: var(--spacing-80);
}

.gap-160{
  gap: var(--spacing-160);
}


/* AUTO */
.margin-left-auto {
  margin-left: auto;
}
.margin-right-auto {
  margin-right: auto;
}

@media (max-width: 1024px) {
  .margin-left-auto-mobile {
    margin-left: auto;
  }
}
@media (max-width: 1200px) {
  .margin-right-auto-mobile {
    margin-right: auto;
  }
}

.margin-12 {
  margin: var(--spacing-12);
}
.margin-top-12 {
  margin-top: var(--spacing-12);
}
.margin-bottom-12 {
  margin-bottom: var(--spacing-12);
}
.margin-left-12 {
  margin-left: var(--spacing-12);
}
.margin-right-12 {
  margin-right: var(--spacing-12);
}
.padding-12 {
  padding: var(--spacing-12);
}
.padding-top-12 {
  padding-top: var(--spacing-12);
}
.padding-bottom-12 {
  padding-bottom: var(--spacing-12);
}
.padding-left-12 {
  padding-left: var(--spacing-12);
}
.padding-right-12 {
  padding-right: var(--spacing-12);
}
.top-12 {
  top: var(--spacing-12);
}
.bottom-12 {
  bottom: var(--spacing-12);
}
.left-12 {
  left: var(--spacing-12);
}
.right-12 {
  right: var(--spacing-12);
}


.margin-64 {
  margin: var(--spacing-64);
}
.margin-top-64 {
  margin-top: var(--spacing-64);
}
.margin-bottom-64 {
  margin-bottom: var(--spacing-64);
}
.margin-left-64 {
  margin-left: var(--spacing-64);
}
.margin-right-64 {
  margin-right: var(--spacing-64);
}
.padding-64 {
  padding: var(--spacing-64);
}
.padding-top-64 {
  padding-top: var(--spacing-64);
}
.padding-bottom-64 {
  padding-bottom: var(--spacing-64);
}
.padding-left-64 {
  padding-left: var(--spacing-64);
}
.padding-right-64 {
  padding-right: var(--spacing-64);
}
.top-64 {
  top: var(--spacing-64);
}
.bottom-64 {
  bottom: var(--spacing-64);
}
.left-64 {
  left: var(--spacing-64);
}
.right-64 {
  right: var(--spacing-64);
}


.margin-80 {
  margin: var(--spacing-80);
}
.margin-top-80 {
  margin-top: var(--spacing-80);
}
.margin-bottom-80 {
  margin-bottom: var(--spacing-80);
}
.margin-left-80 {
  margin-left: var(--spacing-80);
}
.margin-right-80 {
  margin-right: var(--spacing-80);
}
.padding-80 {
  padding: var(--spacing-80);
}
.padding-top-80 {
  padding-top: var(--spacing-80);
}
.padding-bottom-80 {
  padding-bottom: var(--spacing-80);
}
.padding-left-80 {
  padding-left: var(--spacing-80);
}
.padding-right-80 {
  padding-right: var(--spacing-80);
}
.top-80 {
  top: var(--spacing-80);
}
.bottom-80 {
  bottom: var(--spacing-80);
}
.left-80 {
  left: var(--spacing-80);
}
.right-80 {
  right: var(--spacing-80);
}


.margin-16 {
  margin: var(--spacing-16);
}
.margin-top-16 {
  margin-top: var(--spacing-16);
}
.margin-bottom-16 {
  margin-bottom: var(--spacing-16);
}
.margin-left-16 {
  margin-left: var(--spacing-16);
}
.margin-right-16 {
  margin-right: var(--spacing-16);
}
.padding-16 {
  padding: var(--spacing-16);
}
.padding-top-16 {
  padding-top: var(--spacing-16);
}
.padding-bottom-16 {
  padding-bottom: var(--spacing-16);
}
.padding-left-16 {
  padding-left: var(--spacing-16);
}
.padding-right-16 {
  padding-right: var(--spacing-16);
}
.top-16 {
  top: var(--spacing-16);
}
.bottom-16 {
  bottom: var(--spacing-16);
}
.left-16 {
  left: var(--spacing-16);
}
.right-16 {
  right: var(--spacing-16);
}


.margin-8 {
  margin: var(--spacing-8);
}
.margin-top-8 {
  margin-top: var(--spacing-8);
}
.margin-bottom-8 {
  margin-bottom: var(--spacing-8);
}
.margin-left-8 {
  margin-left: var(--spacing-8);
}
.margin-right-8 {
  margin-right: var(--spacing-8);
}
.padding-8 {
  padding: var(--spacing-8);
}
.padding-top-8 {
  padding-top: var(--spacing-8);
}
.padding-bottom-8 {
  padding-bottom: var(--spacing-8);
}
.padding-left-8 {
  padding-left: var(--spacing-8);
}
.padding-right-8 {
  padding-right: var(--spacing-8);
}
.top-8 {
  top: var(--spacing-8);
}
.bottom-8 {
  bottom: var(--spacing-8);
}
.left-8 {
  left: var(--spacing-8);
}
.right-8 {
  right: var(--spacing-8);
}


.margin-160 {
  margin: var(--spacing-160);
}
.margin-top-160 {
  margin-top: var(--spacing-160);
}
.margin-bottom-160 {
  margin-bottom: var(--spacing-160);
}
.margin-left-160 {
  margin-left: var(--spacing-160);
}
.margin-right-160 {
  margin-right: var(--spacing-160);
}
.padding-160 {
  padding: var(--spacing-160);
}
.padding-top-160 {
  padding-top: var(--spacing-160);
}
.padding-bottom-160 {
  padding-bottom: var(--spacing-160);
}
.padding-left-160 {
  padding-left: var(--spacing-160);
}
.padding-right-160 {
  padding-right: var(--spacing-160);
}
.top-160 {
  top: var(--spacing-160);
}
.bottom-160 {
  bottom: var(--spacing-160);
}
.left-160 {
  left: var(--spacing-160);
}
.right-160 {
  right: var(--spacing-160);
}


.margin-40 {
  margin: var(--spacing-40);
}
.margin-top-40 {
  margin-top: var(--spacing-40);
}
.margin-bottom-40 {
  margin-bottom: var(--spacing-40);
}
.margin-left-40 {
  margin-left: var(--spacing-40);
}
.margin-right-40 {
  margin-right: var(--spacing-40);
}
.padding-40 {
  padding: var(--spacing-40);
}
.padding-top-40 {
  padding-top: var(--spacing-40);
}
.padding-bottom-40 {
  padding-bottom: var(--spacing-40);
}
.padding-left-40 {
  padding-left: var(--spacing-40);
}
.padding-right-40 {
  padding-right: var(--spacing-40);
}
.top-40 {
  top: var(--spacing-40);
}
.bottom-40 {
  bottom: var(--spacing-40);
}
.left-40 {
  left: var(--spacing-40);
}
.right-40 {
  right: var(--spacing-40);
}


.margin-24 {
  margin: var(--spacing-24);
}
.margin-top-24 {
  margin-top: var(--spacing-24);
}
.margin-bottom-24 {
  margin-bottom: var(--spacing-24);
}
.margin-left-24 {
  margin-left: var(--spacing-24);
}
.margin-right-24 {
  margin-right: var(--spacing-24);
}
.padding-24 {
  padding: var(--spacing-24);
}
.padding-top-24 {
  padding-top: var(--spacing-24);
}
.padding-bottom-24 {
  padding-bottom: var(--spacing-24);
}
.padding-left-24 {
  padding-left: var(--spacing-24);
}
.padding-right-24 {
  padding-right: var(--spacing-24);
}
.top-24 {
  top: var(--spacing-24);
}
.bottom-24 {
  bottom: var(--spacing-24);
}
.left-24 {
  left: var(--spacing-24);
}
.right-24 {
  right: var(--spacing-24);
}


.margin-4 {
  margin: var(--spacing-4);
}
.margin-top-4 {
  margin-top: var(--spacing-4);
}
.margin-bottom-4 {
  margin-bottom: var(--spacing-4);
}
.margin-left-4 {
  margin-left: var(--spacing-4);
}
.margin-right-4 {
  margin-right: var(--spacing-4);
}
.padding-4 {
  padding: var(--spacing-4);
}
.padding-top-4 {
  padding-top: var(--spacing-4);
}
.padding-bottom-4 {
  padding-bottom: var(--spacing-4);
}
.padding-left-4 {
  padding-left: var(--spacing-4);
}
.padding-right-4 {
  padding-right: var(--spacing-4);
}
.top-4 {
  top: var(--spacing-4);
}
.bottom-4 {
  bottom: var(--spacing-4);
}
.left-4 {
  left: var(--spacing-4);
}
.right-4 {
  right: var(--spacing-4);
}


@media (max-width: 768px) {
  .margin-12-mobile {
    margin: var(--spacing-12);
  }
  .margin-top-12-mobile {
    margin-top: var(--spacing-12);
  }
  .margin-bottom-12-mobile {
    margin-bottom: var(--spacing-12);
  }
  .margin-left-12-mobile {
    margin-left: var(--spacing-12);
  }
  .margin-right-12-mobile {
    margin-right: var(--spacing-12);
  }
  .padding-12-mobile {
    padding: var(--spacing-12);
  }
  .padding-top-12-mobile {
    padding-top: var(--spacing-12);
  }
  .padding-bottom-12-mobile {
    padding-bottom: var(--spacing-12);
  }
  .padding-left-12-mobile {
    padding-left: var(--spacing-12);
  }
  .padding-right-12-mobile {
    padding-right: var(--spacing-12);
  }
  .top-12-mobile {
    top: var(--spacing-12);
  }
  .bottom-12-mobile {
    bottom: var(--spacing-12);
  }
  .left-12-mobile {
    left: var(--spacing-12);
  }
  .right-12-mobile {
    right: var(--spacing-12);
  }
}

@media (max-width: 1024px) {
  .margin-12-tablet {
    margin: var(--spacing-12);
  }
  .margin-top-12-tablet {
    margin-top: var(--spacing-12);
  }
  .margin-bottom-12-tablet {
    margin-bottom: var(--spacing-12);
  }
  .margin-left-12-tablet {
    margin-left: var(--spacing-12);
  }
  .margin-right-12-tablet {
    margin-right: var(--spacing-12);
  }
  .padding-12-tablet {
    padding: var(--spacing-12);
  }
  .padding-top-12-tablet {
    padding-top: var(--spacing-12);
  }
  .padding-bottom-12-tablet {
    padding-bottom: var(--spacing-12);
  }
  .padding-left-12-tablet {
    padding-left: var(--spacing-12);
  }
  .padding-right-12-tablet {
    padding-right: var(--spacing-12);
  }
  .top-12-tablet {
    top: var(--spacing-12);
  }
  .bottom-12-tablet {
    bottom: var(--spacing-12);
  }
  .left-12-tablet {
    left: var(--spacing-12);
  }
  .right-12-tablet {
    right: var(--spacing-12);
  }
}

@media (max-width: 1200px) {
  .margin-12-laptop {
    margin: var(--spacing-12);
  }
  .margin-top-12-laptop {
    margin-top: var(--spacing-12);
  }
  .margin-bottom-12-laptop {
    margin-bottom: var(--spacing-12);
  }
  .margin-left-12-laptop {
    margin-left: var(--spacing-12);
  }
  .margin-right-12-laptop {
    margin-right: var(--spacing-12);
  }
  .padding-12-laptop {
    padding: var(--spacing-12);
  }
  .padding-top-12-laptop {
    padding-top: var(--spacing-12);
  }
  .padding-bottom-12-laptop {
    padding-bottom: var(--spacing-12);
  }
  .padding-left-12-laptop {
    padding-left: var(--spacing-12);
  }
  .padding-right-12-laptop {
    padding-right: var(--spacing-12);
  }
  .top-12-laptop {
    top: var(--spacing-12);
  }
  .bottom-12-laptop {
    bottom: var(--spacing-12);
  }
  .left-12-laptop {
    left: var(--spacing-12);
  }
  .right-12-laptop {
    right: var(--spacing-12);
  }
}

@media (max-width: 1440px) {
  .margin-12-desktop {
    margin: var(--spacing-12);
  }
  .margin-top-12-desktop {
    margin-top: var(--spacing-12);
  }
  .margin-bottom-12-desktop {
    margin-bottom: var(--spacing-12);
  }
  .margin-left-12-desktop {
    margin-left: var(--spacing-12);
  }
  .margin-right-12-desktop {
    margin-right: var(--spacing-12);
  }
  .padding-12-desktop {
    padding: var(--spacing-12);
  }
  .padding-top-12-desktop {
    padding-top: var(--spacing-12);
  }
  .padding-bottom-12-desktop {
    padding-bottom: var(--spacing-12);
  }
  .padding-left-12-desktop {
    padding-left: var(--spacing-12);
  }
  .padding-right-12-desktop {
    padding-right: var(--spacing-12);
  }
  .top-12-desktop {
    top: var(--spacing-12);
  }
  .bottom-12-desktop {
    bottom: var(--spacing-12);
  }
  .left-12-desktop {
    left: var(--spacing-12);
  }
  .right-12-desktop {
    right: var(--spacing-12);
  }
}

@media (max-width: 768px) {
  .margin-64-mobile {
    margin: var(--spacing-64);
  }
  .margin-top-64-mobile {
    margin-top: var(--spacing-64);
  }
  .margin-bottom-64-mobile {
    margin-bottom: var(--spacing-64);
  }
  .margin-left-64-mobile {
    margin-left: var(--spacing-64);
  }
  .margin-right-64-mobile {
    margin-right: var(--spacing-64);
  }
  .padding-64-mobile {
    padding: var(--spacing-64);
  }
  .padding-top-64-mobile {
    padding-top: var(--spacing-64);
  }
  .padding-bottom-64-mobile {
    padding-bottom: var(--spacing-64);
  }
  .padding-left-64-mobile {
    padding-left: var(--spacing-64);
  }
  .padding-right-64-mobile {
    padding-right: var(--spacing-64);
  }
  .top-64-mobile {
    top: var(--spacing-64);
  }
  .bottom-64-mobile {
    bottom: var(--spacing-64);
  }
  .left-64-mobile {
    left: var(--spacing-64);
  }
  .right-64-mobile {
    right: var(--spacing-64);
  }
}

@media (max-width: 1024px) {
  .margin-64-tablet {
    margin: var(--spacing-64);
  }
  .margin-top-64-tablet {
    margin-top: var(--spacing-64);
  }
  .margin-bottom-64-tablet {
    margin-bottom: var(--spacing-64);
  }
  .margin-left-64-tablet {
    margin-left: var(--spacing-64);
  }
  .margin-right-64-tablet {
    margin-right: var(--spacing-64);
  }
  .padding-64-tablet {
    padding: var(--spacing-64);
  }
  .padding-top-64-tablet {
    padding-top: var(--spacing-64);
  }
  .padding-bottom-64-tablet {
    padding-bottom: var(--spacing-64);
  }
  .padding-left-64-tablet {
    padding-left: var(--spacing-64);
  }
  .padding-right-64-tablet {
    padding-right: var(--spacing-64);
  }
  .top-64-tablet {
    top: var(--spacing-64);
  }
  .bottom-64-tablet {
    bottom: var(--spacing-64);
  }
  .left-64-tablet {
    left: var(--spacing-64);
  }
  .right-64-tablet {
    right: var(--spacing-64);
  }
}

@media (max-width: 1200px) {
  .margin-64-laptop {
    margin: var(--spacing-64);
  }
  .margin-top-64-laptop {
    margin-top: var(--spacing-64);
  }
  .margin-bottom-64-laptop {
    margin-bottom: var(--spacing-64);
  }
  .margin-left-64-laptop {
    margin-left: var(--spacing-64);
  }
  .margin-right-64-laptop {
    margin-right: var(--spacing-64);
  }
  .padding-64-laptop {
    padding: var(--spacing-64);
  }
  .padding-top-64-laptop {
    padding-top: var(--spacing-64);
  }
  .padding-bottom-64-laptop {
    padding-bottom: var(--spacing-64);
  }
  .padding-left-64-laptop {
    padding-left: var(--spacing-64);
  }
  .padding-right-64-laptop {
    padding-right: var(--spacing-64);
  }
  .top-64-laptop {
    top: var(--spacing-64);
  }
  .bottom-64-laptop {
    bottom: var(--spacing-64);
  }
  .left-64-laptop {
    left: var(--spacing-64);
  }
  .right-64-laptop {
    right: var(--spacing-64);
  }
}

@media (max-width: 1440px) {
  .margin-64-desktop {
    margin: var(--spacing-64);
  }
  .margin-top-64-desktop {
    margin-top: var(--spacing-64);
  }
  .margin-bottom-64-desktop {
    margin-bottom: var(--spacing-64);
  }
  .margin-left-64-desktop {
    margin-left: var(--spacing-64);
  }
  .margin-right-64-desktop {
    margin-right: var(--spacing-64);
  }
  .padding-64-desktop {
    padding: var(--spacing-64);
  }
  .padding-top-64-desktop {
    padding-top: var(--spacing-64);
  }
  .padding-bottom-64-desktop {
    padding-bottom: var(--spacing-64);
  }
  .padding-left-64-desktop {
    padding-left: var(--spacing-64);
  }
  .padding-right-64-desktop {
    padding-right: var(--spacing-64);
  }
  .top-64-desktop {
    top: var(--spacing-64);
  }
  .bottom-64-desktop {
    bottom: var(--spacing-64);
  }
  .left-64-desktop {
    left: var(--spacing-64);
  }
  .right-64-desktop {
    right: var(--spacing-64);
  }
}


@media (max-width: 768px) {
  .margin-80-mobile {
    margin: var(--spacing-80);
  }
  .margin-top-80-mobile {
    margin-top: var(--spacing-80);
  }
  .margin-bottom-80-mobile {
    margin-bottom: var(--spacing-80);
  }
  .margin-left-80-mobile {
    margin-left: var(--spacing-80);
  }
  .margin-right-80-mobile {
    margin-right: var(--spacing-80);
  }
  .padding-80-mobile {
    padding: var(--spacing-80);
  }
  .padding-top-80-mobile {
    padding-top: var(--spacing-80);
  }
  .padding-bottom-80-mobile {
    padding-bottom: var(--spacing-80);
  }
  .padding-left-80-mobile {
    padding-left: var(--spacing-80);
  }
  .padding-right-80-mobile {
    padding-right: var(--spacing-80);
  }
  .top-80-mobile {
    top: var(--spacing-80);
  }
  .bottom-80-mobile {
    bottom: var(--spacing-80);
  }
  .left-80-mobile {
    left: var(--spacing-80);
  }
  .right-80-mobile {
    right: var(--spacing-80);
  }
}

@media (max-width: 1024px) {
  .margin-80-tablet {
    margin: var(--spacing-80);
  }
  .margin-top-80-tablet {
    margin-top: var(--spacing-80);
  }
  .margin-bottom-80-tablet {
    margin-bottom: var(--spacing-80);
  }
  .margin-left-80-tablet {
    margin-left: var(--spacing-80);
  }
  .margin-right-80-tablet {
    margin-right: var(--spacing-80);
  }
  .padding-80-tablet {
    padding: var(--spacing-80);
  }
  .padding-top-80-tablet {
    padding-top: var(--spacing-80);
  }
  .padding-bottom-80-tablet {
    padding-bottom: var(--spacing-80);
  }
  .padding-left-80-tablet {
    padding-left: var(--spacing-80);
  }
  .padding-right-80-tablet {
    padding-right: var(--spacing-80);
  }
  .top-80-tablet {
    top: var(--spacing-80);
  }
  .bottom-80-tablet {
    bottom: var(--spacing-80);
  }
  .left-80-tablet {
    left: var(--spacing-80);
  }
  .right-80-tablet {
    right: var(--spacing-80);
  }
}

@media (max-width: 1200px) {
  .margin-80-laptop {
    margin: var(--spacing-80);
  }
  .margin-top-80-laptop {
    margin-top: var(--spacing-80);
  }
  .margin-bottom-80-laptop {
    margin-bottom: var(--spacing-80);
  }
  .margin-left-80-laptop {
    margin-left: var(--spacing-80);
  }
  .margin-right-80-laptop {
    margin-right: var(--spacing-80);
  }
  .padding-80-laptop {
    padding: var(--spacing-80);
  }
  .padding-top-80-laptop {
    padding-top: var(--spacing-80);
  }
  .padding-bottom-80-laptop {
    padding-bottom: var(--spacing-80);
  }
  .padding-left-80-laptop {
    padding-left: var(--spacing-80);
  }
  .padding-right-80-laptop {
    padding-right: var(--spacing-80);
  }
  .top-80-laptop {
    top: var(--spacing-80);
  }
  .bottom-80-laptop {
    bottom: var(--spacing-80);
  }
  .left-80-laptop {
    left: var(--spacing-80);
  }
  .right-80-laptop {
    right: var(--spacing-80);
  }
}

@media (max-width: 1440px) {
  .margin-80-desktop {
    margin: var(--spacing-80);
  }
  .margin-top-80-desktop {
    margin-top: var(--spacing-80);
  }
  .margin-bottom-80-desktop {
    margin-bottom: var(--spacing-80);
  }
  .margin-left-80-desktop {
    margin-left: var(--spacing-80);
  }
  .margin-right-80-desktop {
    margin-right: var(--spacing-80);
  }
  .padding-80-desktop {
    padding: var(--spacing-80);
  }
  .padding-top-80-desktop {
    padding-top: var(--spacing-80);
  }
  .padding-bottom-80-desktop {
    padding-bottom: var(--spacing-80);
  }
  .padding-left-80-desktop {
    padding-left: var(--spacing-80);
  }
  .padding-right-80-desktop {
    padding-right: var(--spacing-80);
  }
  .top-80-desktop {
    top: var(--spacing-80);
  }
  .bottom-80-desktop {
    bottom: var(--spacing-80);
  }
  .left-80-desktop {
    left: var(--spacing-80);
  }
  .right-80-desktop {
    right: var(--spacing-80);
  }
}


@media (max-width: 768px) {
  .margin-16-mobile {
    margin: var(--spacing-16);
  }
  .margin-top-16-mobile {
    margin-top: var(--spacing-16);
  }
  .margin-bottom-16-mobile {
    margin-bottom: var(--spacing-16);
  }
  .margin-left-16-mobile {
    margin-left: var(--spacing-16);
  }
  .margin-right-16-mobile {
    margin-right: var(--spacing-16);
  }
  .padding-16-mobile {
    padding: var(--spacing-16);
  }
  .padding-top-16-mobile {
    padding-top: var(--spacing-16);
  }
  .padding-bottom-16-mobile {
    padding-bottom: var(--spacing-16);
  }
  .padding-left-16-mobile {
    padding-left: var(--spacing-16);
  }
  .padding-right-16-mobile {
    padding-right: var(--spacing-16);
  }
  .top-16-mobile {
    top: var(--spacing-16);
  }
  .bottom-16-mobile {
    bottom: var(--spacing-16);
  }
  .left-16-mobile {
    left: var(--spacing-16);
  }
  .right-16-mobile {
    right: var(--spacing-16);
  }
}

@media (max-width: 1024px) {
  .margin-16-tablet {
    margin: var(--spacing-16);
  }
  .margin-top-16-tablet {
    margin-top: var(--spacing-16);
  }
  .margin-bottom-16-tablet {
    margin-bottom: var(--spacing-16);
  }
  .margin-left-16-tablet {
    margin-left: var(--spacing-16);
  }
  .margin-right-16-tablet {
    margin-right: var(--spacing-16);
  }
  .padding-16-tablet {
    padding: var(--spacing-16);
  }
  .padding-top-16-tablet {
    padding-top: var(--spacing-16);
  }
  .padding-bottom-16-tablet {
    padding-bottom: var(--spacing-16);
  }
  .padding-left-16-tablet {
    padding-left: var(--spacing-16);
  }
  .padding-right-16-tablet {
    padding-right: var(--spacing-16);
  }
  .top-16-tablet {
    top: var(--spacing-16);
  }
  .bottom-16-tablet {
    bottom: var(--spacing-16);
  }
  .left-16-tablet {
    left: var(--spacing-16);
  }
  .right-16-tablet {
    right: var(--spacing-16);
  }
}

@media (max-width: 1200px) {
  .margin-16-laptop {
    margin: var(--spacing-16);
  }
  .margin-top-16-laptop {
    margin-top: var(--spacing-16);
  }
  .margin-bottom-16-laptop {
    margin-bottom: var(--spacing-16);
  }
  .margin-left-16-laptop {
    margin-left: var(--spacing-16);
  }
  .margin-right-16-laptop {
    margin-right: var(--spacing-16);
  }
  .padding-16-laptop {
    padding: var(--spacing-16);
  }
  .padding-top-16-laptop {
    padding-top: var(--spacing-16);
  }
  .padding-bottom-16-laptop {
    padding-bottom: var(--spacing-16);
  }
  .padding-left-16-laptop {
    padding-left: var(--spacing-16);
  }
  .padding-right-16-laptop {
    padding-right: var(--spacing-16);
  }
  .top-16-laptop {
    top: var(--spacing-16);
  }
  .bottom-16-laptop {
    bottom: var(--spacing-16);
  }
  .left-16-laptop {
    left: var(--spacing-16);
  }
  .right-16-laptop {
    right: var(--spacing-16);
  }
}

@media (max-width: 1440px) {
  .margin-16-desktop {
    margin: var(--spacing-16);
  }
  .margin-top-16-desktop {
    margin-top: var(--spacing-16);
  }
  .margin-bottom-16-desktop {
    margin-bottom: var(--spacing-16);
  }
  .margin-left-16-desktop {
    margin-left: var(--spacing-16);
  }
  .margin-right-16-desktop {
    margin-right: var(--spacing-16);
  }
  .padding-16-desktop {
    padding: var(--spacing-16);
  }
  .padding-top-16-desktop {
    padding-top: var(--spacing-16);
  }
  .padding-bottom-16-desktop {
    padding-bottom: var(--spacing-16);
  }
  .padding-left-16-desktop {
    padding-left: var(--spacing-16);
  }
  .padding-right-16-desktop {
    padding-right: var(--spacing-16);
  }
  .top-16-desktop {
    top: var(--spacing-16);
  }
  .bottom-16-desktop {
    bottom: var(--spacing-16);
  }
  .left-16-desktop {
    left: var(--spacing-16);
  }
  .right-16-desktop {
    right: var(--spacing-16);
  }
}


@media (max-width: 768px) {
  .margin-8-mobile {
    margin: var(--spacing-8);
  }
  .margin-top-8-mobile {
    margin-top: var(--spacing-8);
  }
  .margin-bottom-8-mobile {
    margin-bottom: var(--spacing-8);
  }
  .margin-left-8-mobile {
    margin-left: var(--spacing-8);
  }
  .margin-right-8-mobile {
    margin-right: var(--spacing-8);
  }
  .padding-8-mobile {
    padding: var(--spacing-8);
  }
  .padding-top-8-mobile {
    padding-top: var(--spacing-8);
  }
  .padding-bottom-8-mobile {
    padding-bottom: var(--spacing-8);
  }
  .padding-left-8-mobile {
    padding-left: var(--spacing-8);
  }
  .padding-right-8-mobile {
    padding-right: var(--spacing-8);
  }
  .top-8-mobile {
    top: var(--spacing-8);
  }
  .bottom-8-mobile {
    bottom: var(--spacing-8);
  }
  .left-8-mobile {
    left: var(--spacing-8);
  }
  .right-8-mobile {
    right: var(--spacing-8);
  }
}

@media (max-width: 1024px) {
  .margin-8-tablet {
    margin: var(--spacing-8);
  }
  .margin-top-8-tablet {
    margin-top: var(--spacing-8);
  }
  .margin-bottom-8-tablet {
    margin-bottom: var(--spacing-8);
  }
  .margin-left-8-tablet {
    margin-left: var(--spacing-8);
  }
  .margin-right-8-tablet {
    margin-right: var(--spacing-8);
  }
  .padding-8-tablet {
    padding: var(--spacing-8);
  }
  .padding-top-8-tablet {
    padding-top: var(--spacing-8);
  }
  .padding-bottom-8-tablet {
    padding-bottom: var(--spacing-8);
  }
  .padding-left-8-tablet {
    padding-left: var(--spacing-8);
  }
  .padding-right-8-tablet {
    padding-right: var(--spacing-8);
  }
  .top-8-tablet {
    top: var(--spacing-8);
  }
  .bottom-8-tablet {
    bottom: var(--spacing-8);
  }
  .left-8-tablet {
    left: var(--spacing-8);
  }
  .right-8-tablet {
    right: var(--spacing-8);
  }
}

@media (max-width: 1200px) {
  .margin-8-laptop {
    margin: var(--spacing-8);
  }
  .margin-top-8-laptop {
    margin-top: var(--spacing-8);
  }
  .margin-bottom-8-laptop {
    margin-bottom: var(--spacing-8);
  }
  .margin-left-8-laptop {
    margin-left: var(--spacing-8);
  }
  .margin-right-8-laptop {
    margin-right: var(--spacing-8);
  }
  .padding-8-laptop {
    padding: var(--spacing-8);
  }
  .padding-top-8-laptop {
    padding-top: var(--spacing-8);
  }
  .padding-bottom-8-laptop {
    padding-bottom: var(--spacing-8);
  }
  .padding-left-8-laptop {
    padding-left: var(--spacing-8);
  }
  .padding-right-8-laptop {
    padding-right: var(--spacing-8);
  }
  .top-8-laptop {
    top: var(--spacing-8);
  }
  .bottom-8-laptop {
    bottom: var(--spacing-8);
  }
  .left-8-laptop {
    left: var(--spacing-8);
  }
  .right-8-laptop {
    right: var(--spacing-8);
  }
}

@media (max-width: 1440px) {
  .margin-8-desktop {
    margin: var(--spacing-8);
  }
  .margin-top-8-desktop {
    margin-top: var(--spacing-8);
  }
  .margin-bottom-8-desktop {
    margin-bottom: var(--spacing-8);
  }
  .margin-left-8-desktop {
    margin-left: var(--spacing-8);
  }
  .margin-right-8-desktop {
    margin-right: var(--spacing-8);
  }
  .padding-8-desktop {
    padding: var(--spacing-8);
  }
  .padding-top-8-desktop {
    padding-top: var(--spacing-8);
  }
  .padding-bottom-8-desktop {
    padding-bottom: var(--spacing-8);
  }
  .padding-left-8-desktop {
    padding-left: var(--spacing-8);
  }
  .padding-right-8-desktop {
    padding-right: var(--spacing-8);
  }
  .top-8-desktop {
    top: var(--spacing-8);
  }
  .bottom-8-desktop {
    bottom: var(--spacing-8);
  }
  .left-8-desktop {
    left: var(--spacing-8);
  }
  .right-8-desktop {
    right: var(--spacing-8);
  }
}


@media (max-width: 768px) {
  .margin-160-mobile {
    margin: var(--spacing-160);
  }
  .margin-top-160-mobile {
    margin-top: var(--spacing-160);
  }
  .margin-bottom-160-mobile {
    margin-bottom: var(--spacing-160);
  }
  .margin-left-160-mobile {
    margin-left: var(--spacing-160);
  }
  .margin-right-160-mobile {
    margin-right: var(--spacing-160);
  }
  .padding-160-mobile {
    padding: var(--spacing-160);
  }
  .padding-top-160-mobile {
    padding-top: var(--spacing-160);
  }
  .padding-bottom-160-mobile {
    padding-bottom: var(--spacing-160);
  }
  .padding-left-160-mobile {
    padding-left: var(--spacing-160);
  }
  .padding-right-160-mobile {
    padding-right: var(--spacing-160);
  }
  .top-160-mobile {
    top: var(--spacing-160);
  }
  .bottom-160-mobile {
    bottom: var(--spacing-160);
  }
  .left-160-mobile {
    left: var(--spacing-160);
  }
  .right-160-mobile {
    right: var(--spacing-160);
  }
}

@media (max-width: 1024px) {
  .margin-160-tablet {
    margin: var(--spacing-160);
  }
  .margin-top-160-tablet {
    margin-top: var(--spacing-160);
  }
  .margin-bottom-160-tablet {
    margin-bottom: var(--spacing-160);
  }
  .margin-left-160-tablet {
    margin-left: var(--spacing-160);
  }
  .margin-right-160-tablet {
    margin-right: var(--spacing-160);
  }
  .padding-160-tablet {
    padding: var(--spacing-160);
  }
  .padding-top-160-tablet {
    padding-top: var(--spacing-160);
  }
  .padding-bottom-160-tablet {
    padding-bottom: var(--spacing-160);
  }
  .padding-left-160-tablet {
    padding-left: var(--spacing-160);
  }
  .padding-right-160-tablet {
    padding-right: var(--spacing-160);
  }
  .top-160-tablet {
    top: var(--spacing-160);
  }
  .bottom-160-tablet {
    bottom: var(--spacing-160);
  }
  .left-160-tablet {
    left: var(--spacing-160);
  }
  .right-160-tablet {
    right: var(--spacing-160);
  }
}

@media (max-width: 1200px) {
  .margin-160-laptop {
    margin: var(--spacing-160);
  }
  .margin-top-160-laptop {
    margin-top: var(--spacing-160);
  }
  .margin-bottom-160-laptop {
    margin-bottom: var(--spacing-160);
  }
  .margin-left-160-laptop {
    margin-left: var(--spacing-160);
  }
  .margin-right-160-laptop {
    margin-right: var(--spacing-160);
  }
  .padding-160-laptop {
    padding: var(--spacing-160);
  }
  .padding-top-160-laptop {
    padding-top: var(--spacing-160);
  }
  .padding-bottom-160-laptop {
    padding-bottom: var(--spacing-160);
  }
  .padding-left-160-laptop {
    padding-left: var(--spacing-160);
  }
  .padding-right-160-laptop {
    padding-right: var(--spacing-160);
  }
  .top-160-laptop {
    top: var(--spacing-160);
  }
  .bottom-160-laptop {
    bottom: var(--spacing-160);
  }
  .left-160-laptop {
    left: var(--spacing-160);
  }
  .right-160-laptop {
    right: var(--spacing-160);
  }
}

@media (max-width: 1440px) {
  .margin-160-desktop {
    margin: var(--spacing-160);
  }
  .margin-top-160-desktop {
    margin-top: var(--spacing-160);
  }
  .margin-bottom-160-desktop {
    margin-bottom: var(--spacing-160);
  }
  .margin-left-160-desktop {
    margin-left: var(--spacing-160);
  }
  .margin-right-160-desktop {
    margin-right: var(--spacing-160);
  }
  .padding-160-desktop {
    padding: var(--spacing-160);
  }
  .padding-top-160-desktop {
    padding-top: var(--spacing-160);
  }
  .padding-bottom-160-desktop {
    padding-bottom: var(--spacing-160);
  }
  .padding-left-160-desktop {
    padding-left: var(--spacing-160);
  }
  .padding-right-160-desktop {
    padding-right: var(--spacing-160);
  }
  .top-160-desktop {
    top: var(--spacing-160);
  }
  .bottom-160-desktop {
    bottom: var(--spacing-160);
  }
  .left-160-desktop {
    left: var(--spacing-160);
  }
  .right-160-desktop {
    right: var(--spacing-160);
  }
}


@media (max-width: 768px) {
  .margin-40-mobile {
    margin: var(--spacing-40);
  }
  .margin-top-40-mobile {
    margin-top: var(--spacing-40);
  }
  .margin-bottom-40-mobile {
    margin-bottom: var(--spacing-40);
  }
  .margin-left-40-mobile {
    margin-left: var(--spacing-40);
  }
  .margin-right-40-mobile {
    margin-right: var(--spacing-40);
  }
  .padding-40-mobile {
    padding: var(--spacing-40);
  }
  .padding-top-40-mobile {
    padding-top: var(--spacing-40);
  }
  .padding-bottom-40-mobile {
    padding-bottom: var(--spacing-40);
  }
  .padding-left-40-mobile {
    padding-left: var(--spacing-40);
  }
  .padding-right-40-mobile {
    padding-right: var(--spacing-40);
  }
  .top-40-mobile {
    top: var(--spacing-40);
  }
  .bottom-40-mobile {
    bottom: var(--spacing-40);
  }
  .left-40-mobile {
    left: var(--spacing-40);
  }
  .right-40-mobile {
    right: var(--spacing-40);
  }
}

@media (max-width: 1024px) {
  .margin-40-tablet {
    margin: var(--spacing-40);
  }
  .margin-top-40-tablet {
    margin-top: var(--spacing-40);
  }
  .margin-bottom-40-tablet {
    margin-bottom: var(--spacing-40);
  }
  .margin-left-40-tablet {
    margin-left: var(--spacing-40);
  }
  .margin-right-40-tablet {
    margin-right: var(--spacing-40);
  }
  .padding-40-tablet {
    padding: var(--spacing-40);
  }
  .padding-top-40-tablet {
    padding-top: var(--spacing-40);
  }
  .padding-bottom-40-tablet {
    padding-bottom: var(--spacing-40);
  }
  .padding-left-40-tablet {
    padding-left: var(--spacing-40);
  }
  .padding-right-40-tablet {
    padding-right: var(--spacing-40);
  }
  .top-40-tablet {
    top: var(--spacing-40);
  }
  .bottom-40-tablet {
    bottom: var(--spacing-40);
  }
  .left-40-tablet {
    left: var(--spacing-40);
  }
  .right-40-tablet {
    right: var(--spacing-40);
  }
}

@media (max-width: 1200px) {
  .margin-40-laptop {
    margin: var(--spacing-40);
  }
  .margin-top-40-laptop {
    margin-top: var(--spacing-40);
  }
  .margin-bottom-40-laptop {
    margin-bottom: var(--spacing-40);
  }
  .margin-left-40-laptop {
    margin-left: var(--spacing-40);
  }
  .margin-right-40-laptop {
    margin-right: var(--spacing-40);
  }
  .padding-40-laptop {
    padding: var(--spacing-40);
  }
  .padding-top-40-laptop {
    padding-top: var(--spacing-40);
  }
  .padding-bottom-40-laptop {
    padding-bottom: var(--spacing-40);
  }
  .padding-left-40-laptop {
    padding-left: var(--spacing-40);
  }
  .padding-right-40-laptop {
    padding-right: var(--spacing-40);
  }
  .top-40-laptop {
    top: var(--spacing-40);
  }
  .bottom-40-laptop {
    bottom: var(--spacing-40);
  }
  .left-40-laptop {
    left: var(--spacing-40);
  }
  .right-40-laptop {
    right: var(--spacing-40);
  }
}

@media (max-width: 1440px) {
  .margin-40-desktop {
    margin: var(--spacing-40);
  }
  .margin-top-40-desktop {
    margin-top: var(--spacing-40);
  }
  .margin-bottom-40-desktop {
    margin-bottom: var(--spacing-40);
  }
  .margin-left-40-desktop {
    margin-left: var(--spacing-40);
  }
  .margin-right-40-desktop {
    margin-right: var(--spacing-40);
  }
  .padding-40-desktop {
    padding: var(--spacing-40);
  }
  .padding-top-40-desktop {
    padding-top: var(--spacing-40);
  }
  .padding-bottom-40-desktop {
    padding-bottom: var(--spacing-40);
  }
  .padding-left-40-desktop {
    padding-left: var(--spacing-40);
  }
  .padding-right-40-desktop {
    padding-right: var(--spacing-40);
  }
  .top-40-desktop {
    top: var(--spacing-40);
  }
  .bottom-40-desktop {
    bottom: var(--spacing-40);
  }
  .left-40-desktop {
    left: var(--spacing-40);
  }
  .right-40-desktop {
    right: var(--spacing-40);
  }
}


@media (max-width: 768px) {
  .margin-24-mobile {
    margin: var(--spacing-24);
  }
  .margin-top-24-mobile {
    margin-top: var(--spacing-24);
  }
  .margin-bottom-24-mobile {
    margin-bottom: var(--spacing-24);
  }
  .margin-left-24-mobile {
    margin-left: var(--spacing-24);
  }
  .margin-right-24-mobile {
    margin-right: var(--spacing-24);
  }
  .padding-24-mobile {
    padding: var(--spacing-24);
  }
  .padding-top-24-mobile {
    padding-top: var(--spacing-24);
  }
  .padding-bottom-24-mobile {
    padding-bottom: var(--spacing-24);
  }
  .padding-left-24-mobile {
    padding-left: var(--spacing-24);
  }
  .padding-right-24-mobile {
    padding-right: var(--spacing-24);
  }
  .top-24-mobile {
    top: var(--spacing-24);
  }
  .bottom-24-mobile {
    bottom: var(--spacing-24);
  }
  .left-24-mobile {
    left: var(--spacing-24);
  }
  .right-24-mobile {
    right: var(--spacing-24);
  }
}

@media (max-width: 1024px) {
  .margin-24-tablet {
    margin: var(--spacing-24);
  }
  .margin-top-24-tablet {
    margin-top: var(--spacing-24);
  }
  .margin-bottom-24-tablet {
    margin-bottom: var(--spacing-24);
  }
  .margin-left-24-tablet {
    margin-left: var(--spacing-24);
  }
  .margin-right-24-tablet {
    margin-right: var(--spacing-24);
  }
  .padding-24-tablet {
    padding: var(--spacing-24);
  }
  .padding-top-24-tablet {
    padding-top: var(--spacing-24);
  }
  .padding-bottom-24-tablet {
    padding-bottom: var(--spacing-24);
  }
  .padding-left-24-tablet {
    padding-left: var(--spacing-24);
  }
  .padding-right-24-tablet {
    padding-right: var(--spacing-24);
  }
  .top-24-tablet {
    top: var(--spacing-24);
  }
  .bottom-24-tablet {
    bottom: var(--spacing-24);
  }
  .left-24-tablet {
    left: var(--spacing-24);
  }
  .right-24-tablet {
    right: var(--spacing-24);
  }
}

@media (max-width: 1200px) {
  .margin-24-laptop {
    margin: var(--spacing-24);
  }
  .margin-top-24-laptop {
    margin-top: var(--spacing-24);
  }
  .margin-bottom-24-laptop {
    margin-bottom: var(--spacing-24);
  }
  .margin-left-24-laptop {
    margin-left: var(--spacing-24);
  }
  .margin-right-24-laptop {
    margin-right: var(--spacing-24);
  }
  .padding-24-laptop {
    padding: var(--spacing-24);
  }
  .padding-top-24-laptop {
    padding-top: var(--spacing-24);
  }
  .padding-bottom-24-laptop {
    padding-bottom: var(--spacing-24);
  }
  .padding-left-24-laptop {
    padding-left: var(--spacing-24);
  }
  .padding-right-24-laptop {
    padding-right: var(--spacing-24);
  }
  .top-24-laptop {
    top: var(--spacing-24);
  }
  .bottom-24-laptop {
    bottom: var(--spacing-24);
  }
  .left-24-laptop {
    left: var(--spacing-24);
  }
  .right-24-laptop {
    right: var(--spacing-24);
  }
}

@media (max-width: 1440px) {
  .margin-24-desktop {
    margin: var(--spacing-24);
  }
  .margin-top-24-desktop {
    margin-top: var(--spacing-24);
  }
  .margin-bottom-24-desktop {
    margin-bottom: var(--spacing-24);
  }
  .margin-left-24-desktop {
    margin-left: var(--spacing-24);
  }
  .margin-right-24-desktop {
    margin-right: var(--spacing-24);
  }
  .padding-24-desktop {
    padding: var(--spacing-24);
  }
  .padding-top-24-desktop {
    padding-top: var(--spacing-24);
  }
  .padding-bottom-24-desktop {
    padding-bottom: var(--spacing-24);
  }
  .padding-left-24-desktop {
    padding-left: var(--spacing-24);
  }
  .padding-right-24-desktop {
    padding-right: var(--spacing-24);
  }
  .top-24-desktop {
    top: var(--spacing-24);
  }
  .bottom-24-desktop {
    bottom: var(--spacing-24);
  }
  .left-24-desktop {
    left: var(--spacing-24);
  }
  .right-24-desktop {
    right: var(--spacing-24);
  }
}


@media (max-width: 768px) {
  .margin-4-mobile {
    margin: var(--spacing-4);
  }
  .margin-top-4-mobile {
    margin-top: var(--spacing-4);
  }
  .margin-bottom-4-mobile {
    margin-bottom: var(--spacing-4);
  }
  .margin-left-4-mobile {
    margin-left: var(--spacing-4);
  }
  .margin-right-4-mobile {
    margin-right: var(--spacing-4);
  }
  .padding-4-mobile {
    padding: var(--spacing-4);
  }
  .padding-top-4-mobile {
    padding-top: var(--spacing-4);
  }
  .padding-bottom-4-mobile {
    padding-bottom: var(--spacing-4);
  }
  .padding-left-4-mobile {
    padding-left: var(--spacing-4);
  }
  .padding-right-4-mobile {
    padding-right: var(--spacing-4);
  }
  .top-4-mobile {
    top: var(--spacing-4);
  }
  .bottom-4-mobile {
    bottom: var(--spacing-4);
  }
  .left-4-mobile {
    left: var(--spacing-4);
  }
  .right-4-mobile {
    right: var(--spacing-4);
  }
}

@media (max-width: 1024px) {
  .margin-4-tablet {
    margin: var(--spacing-4);
  }
  .margin-top-4-tablet {
    margin-top: var(--spacing-4);
  }
  .margin-bottom-4-tablet {
    margin-bottom: var(--spacing-4);
  }
  .margin-left-4-tablet {
    margin-left: var(--spacing-4);
  }
  .margin-right-4-tablet {
    margin-right: var(--spacing-4);
  }
  .padding-4-tablet {
    padding: var(--spacing-4);
  }
  .padding-top-4-tablet {
    padding-top: var(--spacing-4);
  }
  .padding-bottom-4-tablet {
    padding-bottom: var(--spacing-4);
  }
  .padding-left-4-tablet {
    padding-left: var(--spacing-4);
  }
  .padding-right-4-tablet {
    padding-right: var(--spacing-4);
  }
  .top-4-tablet {
    top: var(--spacing-4);
  }
  .bottom-4-tablet {
    bottom: var(--spacing-4);
  }
  .left-4-tablet {
    left: var(--spacing-4);
  }
  .right-4-tablet {
    right: var(--spacing-4);
  }
}

@media (max-width: 1200px) {
  .margin-4-laptop {
    margin: var(--spacing-4);
  }
  .margin-top-4-laptop {
    margin-top: var(--spacing-4);
  }
  .margin-bottom-4-laptop {
    margin-bottom: var(--spacing-4);
  }
  .margin-left-4-laptop {
    margin-left: var(--spacing-4);
  }
  .margin-right-4-laptop {
    margin-right: var(--spacing-4);
  }
  .padding-4-laptop {
    padding: var(--spacing-4);
  }
  .padding-top-4-laptop {
    padding-top: var(--spacing-4);
  }
  .padding-bottom-4-laptop {
    padding-bottom: var(--spacing-4);
  }
  .padding-left-4-laptop {
    padding-left: var(--spacing-4);
  }
  .padding-right-4-laptop {
    padding-right: var(--spacing-4);
  }
  .top-4-laptop {
    top: var(--spacing-4);
  }
  .bottom-4-laptop {
    bottom: var(--spacing-4);
  }
  .left-4-laptop {
    left: var(--spacing-4);
  }
  .right-4-laptop {
    right: var(--spacing-4);
  }
}

@media (max-width: 1440px) {
  .margin-4-desktop {
    margin: var(--spacing-4);
  }
  .margin-top-4-desktop {
    margin-top: var(--spacing-4);
  }
  .margin-bottom-4-desktop {
    margin-bottom: var(--spacing-4);
  }
  .margin-left-4-desktop {
    margin-left: var(--spacing-4);
  }
  .margin-right-4-desktop {
    margin-right: var(--spacing-4);
  }
  .padding-4-desktop {
    padding: var(--spacing-4);
  }
  .padding-top-4-desktop {
    padding-top: var(--spacing-4);
  }
  .padding-bottom-4-desktop {
    padding-bottom: var(--spacing-4);
  }
  .padding-left-4-desktop {
    padding-left: var(--spacing-4);
  }
  .padding-right-4-desktop {
    padding-right: var(--spacing-4);
  }
  .top-4-desktop {
    top: var(--spacing-4);
  }
  .bottom-4-desktop {
    bottom: var(--spacing-4);
  }
  .left-4-desktop {
    left: var(--spacing-4);
  }
  .right-4-desktop {
    right: var(--spacing-4);
  }
}

/* RADIUS */
.radius-s {
  border-radius: var(--radius-s);
}
