.modal {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  /* closed */
  opacity: 0;
  visibility: hidden;

  transition: .2s ease-in-out;
  transition-property: opacity, visibility;
}

.modal.is-open {
  opacity: 1;
  visibility: visible;
}

.modal.is-open .modal_window {
  transform: translate(-50%, -50%);
}

@media (width <= 768px) {
  .modal.is-open .modal_window {
    transform: translate(0, 0);
  }
}

.modal .modal_layer {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--black);
  opacity: 0.6;
}

.modal_window {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  width: calc(100% - 20px);
  max-width: 1200px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  background-color: var(--beige);
  border-radius: var(--radius-s);

  /* closed */
  transform: translate(-50%, calc(-50% + 30px));

  transition: .2s ease-in-out;
  transition-property: transform;
}

@media (width <= 768px) {
  .modal_window {
    top: initial;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 10px 10px 0 0;

    /* closed */
    transform: translate(0, 30px);
  }
}

.modal_window .modal_body {
  padding: var(--spacing-24);
}

@media (width <= 768px) {
  .modal_window .modal_body {
    padding: var(--spacing-24) var(--spacing-16);
  }
}